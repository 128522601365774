<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<div>
								<InputSwitch v-model="$store.state.opc"  style="margin-top: 0" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.ini" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.fin" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<Dropdown id="state" v-model="$store.state.year" :options="$store.state.years" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." @change="$store.state.mes=nul" v-if="$store.state.opc"></Dropdown>
								<Dropdown id="state" v-model="$store.state.mes" :options="$store.state.meses" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." v-if="$store.state.opc"></Dropdown>
								<Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Selecciona..." class="mr-2"></Dropdown>
								<Button icon="pi pi-search" class="p-button-rounded p-button-info mr-2" @click="Buscar"/>
							</div>
							Total: {{products.length}}
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert"/>
						</div>
					</template>
					<Column field="control" header="Nº Control" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.control}}
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="numero" header="Nº Documento">
						<template #body="slotProps">
							{{slotProps.data.numero}}
						</template>
					</Column>
					<Column field="proveedor" header="Proveedor" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.proveedor}}
						</template>
					</Column>
					<Column field="rif" header="Rif">
						<template #body="slotProps">
							{{slotProps.data.rif}}
						</template>
					</Column>
					<Column header="Monto">
						<template #body="slotProps">
							{{slotProps.data.code}} {{Moneda(slotProps.data.montofinal)}}
						</template>
					</Column>
					<Column header="Estatus">
						<template #body="slotProps">
						<div v-if="slotProps.data.activo">
							<strong v-if="slotProps.data.pagado" style="color:green">
								Pagado
							</strong>
							<strong v-if="!slotProps.data.pagado && slotProps.data.recibido!=0" style="color:orange">
								Pago Parcial<br>
							</strong>  
							<strong v-if="!slotProps.data.pagado && slotProps.data.recibido==0" style="color:red">
								Pendiente
							</strong>  
							<i class="pi pi-sync" v-if="slotProps.data.recurrente"/>
						</div>
						<div v-else>
							<strong style="color:grey">
								Anulado
							</strong> 
						</div>
						</template>
					</Column>				
                    <Column header="Acciones" headerStyle="width: 230px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-1" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.activo && !slotProps.data.pagado"/>
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-1" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.activo && slotProps.data.pagado"/>
							<Button icon="pi pi-play" class="p-button-rounded p-button-secondary mr-1" @click="Procesar(slotProps.data)" v-if="edit && slotProps.data.activo && !slotProps.data.pagado"/>
                            <Button icon="bi bi-files" class="p-button-rounded p-button-info mr-1" @click="Duplicar(slotProps.data)" v-if="insert"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-1" @click="Confirmar(slotProps.data)" v-if="del && slotProps.data.activo && !slotProps.data.pagado"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Editar ID'+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">       
				<div class="grid fluid mt-3">
					<div class="col-4">
						<label for="name">Proveedor</label>
						<Dropdown v-model="product.proveedor" :options="this.proveedores" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
						<small class="p-invalid" v-if="!product.proveedor">Es requirido</small>
					</div>
					<div class="col-2">
						<label for="name">Rif</label>
						<InputText id="name" v-model="product.proveedor.rif" disabled v-if="product.proveedor"/>
					</div>
					<div class="col-2">
						<label for="name">Fecha</label>
						<InputText v-model="product.fecha" type="date" required="true"/>
                        <small class="p-invalid" v-if="!product.fecha">Es requerido.</small>
					</div>
					<div class="col-2">
						<label for="name">Vencimiento</label>
						<div class="p-inputgroup">
							<span class="p-inputgroup-addon">
								<Checkbox v-model="product.vence" :binary="true"/>
							</span>
							<InputText v-model="product.vencimiento" type="date" :disabled="!product.vence"/>
						</div>
					</div>
					<div class="col-2">
						<label for="name">Nº Documento</label>
						<InputText v-model="product.numero" maxlength="10" required="true"/>
                        <small class="p-invalid" v-if="!product.numero">Es requerido.</small>
					</div>
					<div class="col-2">
						<label for="name">Moneda</label>
						<Dropdown v-model="product.moneda" :options="this.$store.state.monedas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
					</div>
					<div class="col-2">
						<label for="price">Monto</label>
						<InputNumber v-model="product.monto" mode="currency" :currency="product.moneda.code" locale="en-US" />
                        <small class="p-invalid" v-if="!product.monto">Es requerido.</small>
					</div>
					<div class="col-2">
						<label for="price">Tasa</label>
						<InputNumber v-model="product.tasa" mode="currency" currency="VEF" locale="en-US" :disabled="product.pagado"/>
                        <small class="p-invalid" v-if="!product.tasa">Es requerido.</small>
					</div>
					<div class="col-2">
						<label for="price">IVA</label> {{product.moneda.code}} {{Moneda(product.monto*(product.iva/100))}}
						<InputNumber v-model="product.iva" suffix="%" :disabled="product.pagado"/>
					</div>
					<div class="col-2">
						<h6 style="margin-top: 0">
							Factura: 
							<strong v-if="product.factura">SI</strong>
							<strong v-else>NO</strong>
						</h6>
						<InputSwitch v-model="product.factura" /> 	
					</div>
					<div class="col-2">
						<h6 style="margin-top: 0">
							Retención: 
							<strong v-if="product.retencion">SI</strong>
							<strong v-else>NO</strong>
						</h6>
                        <InputSwitch v-model="product.retencion"/>
                        <strong v-if="product.retencion">
                            {{product.moneda.code}} {{Moneda(product.monto*(product.iva/100)*(3/4))}}
						</strong>
					</div>
					<div class="col-6">
						<label for="name">Descripción</label>
						<Textarea v-model="product.descripcion" rows="3" cols="20"/>
                        <small class="p-invalid" v-if="!product.descripcion">Es requerido.</small>
					</div>
					<div class="col-6">
						<label for="name">Observación</label>
						<Textarea v-model="product.observacion" rows="3" cols="20"/>
                        <small class="p-invalid" v-if="!product.observacion">Es requerido.</small>
					</div>
					<div class="col-3">
						<h6 style="margin-top: 0">
							Pago Recurente Mensual: 
							<strong v-if="product.recurrente">SI</strong>
							<strong v-else>NO</strong>
						</h6>
                        <InputSwitch v-model="product.recurrente"/>
					</div>
				</div>
			</div>
			<template #footer>
                <h3>
					<strong>
						Monto a Pagar: {{product.moneda.code}} {{Moneda(product.monto*(1+product.iva/100))}}
					</strong>
				</h3>
                <h5 v-if="product.retencion" style="color: red">
					<strong>
						Menos la Retención: {{product.moneda.code}} {{Moneda(product.monto*(1+product.iva/400))}}
					</strong>
				</h5>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()"  :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog"  style="width: 30vw" :header="'Anular ID'+product.id" :modal="true">
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres anular?</span>
			</div>
			<template #footer>
				<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="DialogProcesar" style="width: 60vw" header="Procesar Pago" :modal="true" class="p-fluid" :maximizable="true">
			<Toast/>
			<div class="field">
				<div class="grid">
					<!--
                    <div class="col-6">
                        <div class="card mb-0">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Saldo de Caja Actual</span>
                                    <div class="text-900 font-medium text-xl">$ {{cajaf}}</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                                    <i class="bi bi-cash text-blue-500 text-xl"></i>
                                </div>
                            </div>
                            <span class="text-500">Saldo a la fecha  </span>
                            <span class="text-blue-500 font-medium"> ${{Moneda(caja)}} </span><br>
                            <span class="text-500"> ({{product.fechad}})</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card mb-0">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Saldo de Banco Actual</span>
                                    <div class="text-900 font-medium text-xl">VEF {{Moneda(bancof)}}</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                                    <i class="bi bi-credit-card text-orange-500 text-xl"></i>
                                </div>
                            </div>
                            <span class="text-500">Saldo a la fecha </span>
                            <span class="text-orange-500 font-medium"> VEF{{Moneda(banco)}} </span><br>
                            <span class="text-500"> ({{product.fechad}})</span>
                        </div>
                    </div>
					-->
					<div class="col-12">
						<Card>
							<template #content>
								<div class="fluid formgrid grid">
									<div class="col-4">
										<h5>Control Nº  {{product.control}}</h5>
									</div>
                                    <div class="col-4">
										<h5>Fecha: {{product.fechad}}</h5>
									</div>
									<div class="col-2">
										<h5>Monto:</h5>
									</div>
									<div class="col-2">
										<h5><strong>{{product.code}} {{Moneda(product.montofinal)}}</strong></h5>
									</div>
									<div class="col-12">
										<h5>Proveedor: {{product.proveedor}} - {{product.rif}}</h5>
									</div>
                                    <div class="col-3" v-if="product.recibido<0 && product.recibidobs<0">
										<h5>Pago Parcial:</h5>
									</div>
                                    <div class="col-3" v-if="product.recibido<0 && product.recibidobs<0">
										<h5 v-if="product.moneda_id==2">$ {{Moneda((-1)*product.recibido)}}</h5>
										<h5 v-if="product.moneda_id==1">VEF {{Moneda((-1)*product.recibidobs)}}</h5>
									</div>
								</div>
							</template>
							<template #footer>	
								<strong v-if="($store.state.pago.monto >= product.monto && moneda!=1) || ($store.state.pago.montobs >= product.montobs && moneda==1)" style="color:green">
									<h5>Pago Total</h5>
								</strong>
								<strong v-if="($store.state.pago.monto<parseFloat(product.monto-product.recibido) && $store.state.pago.monto>0 && moneda!=1)
									|| ($store.state.pago.montobs<parseFloat(product.montobs-product.recibidobs) && $store.state.pago.montobs>0  && moneda==1)" style="color:orange">
									<h5>Pago Parcial</h5>
								</strong>
								<strong v-if="$store.state.pago.montobs==0 && $store.state.pago.monto==0 && product.recibido==0 && product.recibidobs==0" style="color:red">
									<h5>Pendiente</h5>
								</strong>
							</template>
						</Card>	
					</div>
					<div class="col-12">
						<ReportarPago :tipopago="formapagos" :deuda="totales" :moneda="moneda"/>
					</div>
				</div>
			</div>
			
			<template #footer>
				<Button label="Procesar" icon="pi pi-check" class="p-button-secondary" @click="Ejecutar()" 
					:disabled="($store.state.pago.monto==0 && $store.state.pago.montobs==0) || ($store.state.loading==true)" :loading="$store.state.loading"/>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="DialogProcesar = false"/>
			</template>
		</Dialog>


	</div>

</template>

<script>
	import API from "../service/API";
	import ReportarPago from '@/components/ReportarPago.vue';
	//import DetallePago from '@/components/DetallePago.vue';
	const Consulta = new API('Administracion','CuentaPagar');
    import Funciones from "../service/Class";
    const Aplic = new Funciones();
	export default {
		components: {
			ReportarPago,
			//DetallePago,
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
                DialogProcesar: false,
				productDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '70vw',
                dataprincipal: [],
				opcion: null,
				opciones: [
					{nombre: 'Todas', code: 0},
					{nombre: 'Pagadas', code: 1},
					{nombre: 'Pendientes', code: 2},
					{nombre: 'Anuladas', code: 3}
				],
                proveedores: [],
                tipopago: [],
                caja: 0,
                banco: 0,
                cajaf: 0,
                bancof: 0,
                formapagos: [],
                totales: {
                    subtotal: 0,
                    descuento: 0,
                    iva: 0,
					final: 0,
                },

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response.result;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
                    this.proveedores = response.result.proveedores;
                    this.tipopago = response.result.tipopago;
					this.$store.commit('Loading');
                    if(this.opcion){this.Buscar();}
				}); 
			},
            Buscar(){
				this.selectedCustomers = [];
				let opciones = {
					filt: this.opcion.code,
					opc: this.$store.state.opc,
					ini: this.$store.state.ini,
					fin: this.$store.state.fin,
					mes: this.$store.state.mes.id,
					year: this.$store.state.year.id,
				};
				Consulta.Procesar('Buscar',{
                    product: opciones,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					if (response.result) {
						this.products = response.result;
					}
				});
            },
			Nuevo() {
				this.nuevo = true;
				this.product = {};
                this.product.id = '';
                this.product.moneda =  this.$store.state.monedas[0];
                this.product.monto = 0;
                this.product.iva = 0;
                this.product.observacion = 'Ninguna';
                this.product.factura = false;
                this.product.retencion = false;
                this.product.vence = false;
				this.product.recurrente = false;
                this.product.tasa = this.$store.state.tasa;
                this.product.validopor = 1;
				this.productDialog = true;
			},
			Duplicar(product) {
				this.nuevo = true;
				this.product = {...product};
                this.product.proveedor =  this.proveedores.filter(val => val.id == this.product.proveedor_id)[0];
                this.product.moneda =  this.$store.state.monedas.filter(val => val.id == this.product.moneda)[0];
                this.product.fecha = null;
                this.product.pagado = false;
                this.productDialog = true;
			},
			Editar(product) {
				this.nuevo = false;
				this.product = {...product};
                this.product.proveedor =  this.proveedores.filter(val => val.id == this.product.proveedor_id)[0];
                this.product.moneda =  this.$store.state.monedas.filter(val => val.id == this.product.moneda)[0];
                this.productDialog = true;
			},
			Guardar() {
				if (this.product.proveedor == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Selecciones el Proveedor', life: 5000});
				} else if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha', life: 5000});
				} else if (this.product.numero == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Número de Documento', life: 5000});
				} else if (this.product.moneda == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Moneda', life: 5000});
				} else if (this.product.monto <= 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Monto', life: 5000});
				} else if (this.product.tasa == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Tasa', life: 5000});
				} else if (!(this.product.descripcion)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Descripción', life: 5000});
				} else if (!(this.product.observacion)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Observación', life: 5000});
				} else {
					var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}
					Consulta.Procesar(caso,{
						product: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Mostrar();
							this.productDialog = false;
							this.product = {};
						}
					});
				}
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
			Procesar(product) {
				this.product = {...product};
				this.caja = 0;
				this.banco = 0;
				Consulta.Procesar('Saldos',{
					product: 	product,
				}).then(response => {
					this.$store.commit('Loading'); 
					//this.$store.state.error = response;
					if (response.result) {
						this.caja = response.result.caja;
						this.banco = response.result.banco;
						this.cajaf = response.result.cajaf;
						this.bancof = response.result.bancof;
						this.$store.commit('LimpiarPagos');
						this.$store.state.tasa = product.tasa;
                        this.formapagos = this.tipopago;
                        this.moneda = product.moneda_id;
						if(product.retencion){
							this.totales.final = parseFloat(product.monto)*(1+parseFloat(product.iva)/400)+parseFloat(product.recibido);
							this.totales.finalbs = parseFloat(product.montobs)*(1+parseFloat(product.iva)/400)+parseFloat(product.recibidobs);
						} else {
							this.totales.final = parseFloat(product.monto)*(1+parseFloat(product.iva)/100)+parseFloat(product.recibido);
							this.totales.finalbs = parseFloat(product.montobs)*(1+parseFloat(product.iva)/100)+parseFloat(product.recibidobs);
						}
                        this.DialogProcesar = true;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
			},
			Ejecutar() {
				if (!this.product == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione', life: 5000});
				} else if (this.$store.state.pago.monto==0 && this.$store.state.pago.montobs==0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la forma de pago', life: 10000});
				} else {
					this.product.pagos = this.$store.state.pago;
					Consulta.Procesar('Procesar',{
						product: 	this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.$store.commit('LimpiarPagos');
							this.DialogProcesar = false;
							this.Buscar();
						} 
					});
				}
            },

            Moneda(value) {
                return Aplic.Moneda(value);
             },



		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
